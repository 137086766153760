@media print {
  @page {
    size: portrait;
    margin-top: 100px;
  }
  html {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .button {
    display: none !important;
  }
  .page-break {
    page-break-inside: avoid;
  }
}

.table td {
  padding-inline-start: 0px;
  padding-inline-end: 12px;
  border-color: #a0aec0 !important;
}

.table tr:last-child td,
.table tr:first-child td:first-child {
  border-width: 0px;
}

.header-table td {
  padding-inline-start: 0px;
  padding-inline-end: 12px;
  border-width: 0;
}
